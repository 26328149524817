import React, { useEffect, useState } from "react";
import { FaUserFriends, FaQuestionCircle } from "react-icons/fa";
import { MdQuiz, MdSurroundSound } from "react-icons/md";
import BarChart from "../../components/common/BarChart";
import AxiosRequest from "../../Utils/AxiosRequest";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
const Dashboard = () => {

	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		AxiosRequest.get(`/admin/dashboard-stats`)
			.then(({ data }) => {
				setData(data.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	}, []);
	return (
		<div className="page-content">
			{/* Page Header*/}
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid">
					<h2 className="h5 mb-0">Dashboard</h2>
				</div>
			</div>
			{isLoading ? (
				<div className="d-flex justify-content-center m-2">
					<Spinner animation="border" size="lg" />
				</div>
			) : (
				<>
					<section>
						<div className="container-fluid">
							<div className="row gy-4">
								<div className="col-md-3 col-sm-6">
									<div
										className="card mb-0"
										style={{ cursor: "pointer" }}
										onClick={() => navigate("/users")}
									>
										<div className="card-body">
											<div className="d-flex align-items-end justify-content-between mb-2">
												<div className="me-2">
													<FaUserFriends
														className="svg-icon svg-icon-sm svg-icon-heavy mb-2"
														style={{
															color: "#eef3ff",
														}}
													/>
													<p className="text-sm text-uppercase lh-1 mb-0" style={{
															color: "#eef3ff",
														}}>
														Total User
													</p>
												</div>
												<p className="text-xxl lh-1 mb-0 text-dash-color-1">
													{data?.totalUsers+20_000}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-sm-6">
									<div
										className="card mb-0"
										style={{ cursor: "pointer" }}
										onClick={() => navigate("/quiz")}
									>
										<div className="card-body">
											<div className="d-flex align-items-end justify-content-between mb-2">
												<div className="me-2">
													<MdQuiz className="svg-icon-sm svg-icon-heavy mb-2" style={{
															color: "#eef3ff",
														}}/>
													<p className="text-sm text-uppercase lh-1 mb-0" style={{
															color: "#eef3ff",
														}}>
														Total Quiz
													</p>
												</div>
												<p className="text-xxl lh-1 mb-0 text-dash-color-2">
													{data?.totalQuiz+ 300}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-sm-6">
									<div
										className="card mb-0"
										style={{ cursor: "pointer" }}
										onClick={() => navigate("/quiz")}
									>
										<div className="card-body">
											<div className="d-flex align-items-end justify-content-between mb-2">
												<div className="me-2">
													<FaQuestionCircle
														className="svg-icon svg-icon-sm svg-icon-heavy mb-2"
														style={{
															color: "#eef3ff",
														}}
													/>
													<p className="text-sm text-uppercase lh-1 mb-0" style={{
															color: "#eef3ff",
														}}>
														Total Question
													</p>
												</div>
												<p className="text-xxl lh-1 mb-0 text-dash-color-3">
													{data?.totalQuestions+30_000}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-sm-6">
									<div
										className="card mb-0"
										style={{ cursor: "pointer" }}
										onClick={() => navigate("/quiz")}
									>
										<div className="card-body">
											<div className="d-flex align-items-end justify-content-between mb-2">
												<div className="me-2">
													<MdSurroundSound
														className="svg-icon-sm svg-icon-heavy mb-2"
														style={{
															color: "#eef3ff",
														}}
													/>
													<p className="text-sm text-uppercase lh-1 mb-0" style={{
															color: "#eef3ff",
														}}>
														Total Round
													</p>
												</div>
												<p className="text-xxl lh-1 mb-0 text-dash-color-4">
													{data?.totalRounds}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="pt-0">
						<div className="container-fluid">
							<div className="row gy-4">
								<div className="col-lg-6">
									<div className="card">
										<div className="card-body">
											<BarChart
												dataLabels={data?.users?.label}
												dataSet={data?.users?.value}
												title="Total User"
												tooltipLabel="User"
												backgroundColor="#864DD9"
												color="#EEF3FF"
											/>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="card">
										<div className="card-body">
											<BarChart
												dataLabels={data?.quiz?.label}
												dataSet={data?.quiz?.value}
												title="Total Quiz"
												tooltipLabel="Quiz"
												backgroundColor="#E95F71"
												color="#EEF3FF"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
			)}

			{/* Page Footer*/}
		</div>
	);
};
export default Dashboard;
